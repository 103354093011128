<app-callback-alert></app-callback-alert>

<!-- header -->
@if(layoutService.headerShown()){
<app-header></app-header>
} @if(layoutService.getStartedAsShown){
<app-get-started-as></app-get-started-as>
}

<!--  -->
@if(isLoading){
<!-- Loading spinner -->
<app-loading-spinner></app-loading-spinner>
}@else if(layoutService.getStartedAsShown){
<!-- routes children -->
<app-get-started-as></app-get-started-as>
<!--  -->
}@else {
<router-outlet></router-outlet>
}

<!--  -->
@if(!layoutService.getStartedAsShown && layoutService.footerShown()){
<!-- footer -->
<app-footer></app-footer>
}

<!-- CIRCULAR NAV -->
@if(layoutService.circularNavShown()){
<app-circular-nav
  [navLinks]="[
    { label: 'Whatsapp', img: '/assets/images/icons/whatsapp.svg', href: 'https://wa.me/message/GAE7WE334ZPFG1', },
    { label: 'Whatsapp', img: '/assets/images/careerai/vcoach.svg', routerLink: '/aicounsellor/chat' },
    ]"
></app-circular-nav>
}
