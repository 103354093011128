import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

interface NavLink {
  label: string;
  href?: string;
  routerLink?: string;
  img?: string;
  [key: string]: any;
}

@Component({
  selector: 'app-circular-nav',
  templateUrl: './circular-nav.component.html',
  imports: [RouterLink],
})
export class NavComponent implements OnInit {
  @Input() navLinks: Array<NavLink> = [];
  @Output() linkClick: EventEmitter<number> = new EventEmitter<number>();

  indexOfActiveLink: number = 0;
  isOpen: boolean = false;
  angles: number[] = [];

  ngOnInit(): void {
    this.angles = Array(this.navLinks.length).fill(-360);
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
    this.updateAngles();
  }

  updateAngles(): void {
    if (this.isOpen) {
      this.angles = this.navLinks.map(
        (_, i) => (360 / this.navLinks.length) * i
      );
    } else {
      this.angles = Array(this.navLinks.length).fill(-360);
    }
  }

  handleLabelClick(index: number): void {
    this.indexOfActiveLink = index;
    this.isOpen = false;
    this.updateAngles();
    this.linkClick.emit(index);
  }
}
