import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

// Functional Route guard to check if the user is logged in
export const authGuard = () => {
  const _router = inject(Router);
  const _authService = inject(AuthService);

  if (_authService.loggedIn()) {
    return true;
  } else {
    _router.navigate(['/login']);
    return false;
  }
};
