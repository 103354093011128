<div id="default_header">
  <!-- Desktop -->
  <header
    class="tw-static tw-top-0 tw-w-full tw-z-50 tw-bg-white xl:tw-flex tw-hidden tw-justify-between tw-items-center tw-gap-5 tw-flex-col"
  >
    <!-- Topbar -->
    <div
      class="tw-flex tw-justify-center tw-w-full tw-shadow-lg tw-shadow-slate-100"
    >
      <div
        class="tw-flex tw-justify-between tw-max-w-[1440px] tw-items-center tw-px-12 tw-w-full"
      >
        <div
          class="tw-w-full tw-flex tw-justify-start tw-gap-2 tw-py-5 tw-items-center"
        >
          <a
            aria-label="Home"
            routerLink="/"
            (click)="layoutService.hideGetStartedAs()"
            class="tw-p-4 tw-rounded-full tw-cursor-pointer hover:tw-scale-[1.01] hover:tw-shadow-sm tw-transition-all tw-duration-300 tw-mr-4 tw-z-10"
          >
            <svg-icon
              src="assets/images/homepage/homeIcon.svg"
              class="tw-h-full tw-block"
              [applyClass]="true"
            />
          </a>
          <!-- Nav menu -->
          @for (navLink of navLinks; track $index){
          <div class="tw-dropdown tw-z-[100]">
            <label
              (click)="handleNavTitleClick()"
              tabindex="0"
              class="tw-px-4 tw-py-2 tw-rounded-full tw-cursor-pointer hover:tw-scale-[1.01] hover:tw-shadow-sm tw-transition-all tw-duration-300 tw-font-semibold tw-text-[13px] tw-flex tw-gap-2 tw-items-center"
              >{{ navLink.name }}
              <svg-icon src="assets/images/homepage/downarrow.svg" />
            </label>
            <ul
              #navList
              class="tw-dropdown-content tw-z-[1] tw-menu tw-p-2 tw-shadow-[#4281FD30] tw-shadow-2xl tw-bg-white tw-text-xs tw-rounded-lg tw-w-max tw-mt-4"
            >
              @for (subNavLink of navLink.subNavLinks; track $index){
              <li
                (click)="toggleNav()"
                class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
              >
                @if (subNavLink.type === 'EXTERNAL_LINK') {
                <a
                  class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                  [href]="subNavLink.url"
                  target="_blank"
                  >{{ subNavLink.name }}</a
                >
                }@else {
                <a
                  class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                  [routerLink]="subNavLink.url"
                  >{{ subNavLink.name }}</a
                >
                }
              </li>
              }
            </ul>
          </div>
          }
        </div>
        <!-- Social Icons -->
        <div class="tw-flex tw-gap-5 tw-h-full tw-items-center tw-z-10">
          <a
            aria-label="Instagram"
            href="https://www.instagram.com/careernaksha"
            target="_blank"
          >
            <svg-icon
              src="assets/images/instagram.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
          <a
            aria-label="Facebook"
            href="https://www.facebook.com/careernaksha"
            target="_blank"
          >
            <svg-icon
              src="assets/images/facebook.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
          <a
            aria-label="Youtube"
            href="https://www.youtube.com/careernaksha"
            target="_blank"
          >
            <svg-icon
              src="assets/images/youtube.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
          <a
            aria-label="Linkedin"
            href="https://www.linkedin.com/company/careernaksha"
            target="_blank"
          >
            <svg-icon
              src="assets/images/linkedin.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- Bottom Navbar -->
    <div
      class="tw-flex tw-w-full tw-justify-between tw-max-w-[1440px] tw-px-12 tw-items-center"
    >
      <a routerLink="/" class="tw-cursor-pointer">
        <svg-icon
          alt="Careernaksha"
          src="assets/images/logoh.svg"
          width="100%"
          height="100%"
          class="tw-h-11"
        />
      </a>
      <div class="tw-flex tw-font-semibold tw-items-center tw-z-10">
        <div class="tw-dropdown tw-dropdown-right tw-rounded-full tw-relative">
          @if(isLoggedIn){
          <div
            class="tw-w-10 tw-h-[2.6rem] tw-py-2 tw-px-3 hover:tw-w-[12.4rem] tw-transition-all tw-duration-300 tw-bg-white tw-border tw-border-BluePrimary tw-cursor-pointer tw-rounded-full tw-absolute tw-group -tw-left-10 -tw-top-[1.3rem] tw-overflow-hidden"
          >
            <svg-icon
              src="assets/images/callicon.svg"
              class="tw-max-w-full tw-max-h-full group-hover:tw-fill-white tw-fill-black tw-transition-all tw-duration-300 group-hover:tw-opacity-0"
              [applyClass]="true"
            />
            <a
              href="tel:+918469149288"
              class="tw-font-semibold tw-opacity-0 group-hover:tw-opacity-100 tw-absolute tw-w-[12rem] tw-transition-all tw-duration-300 tw-top-2 hover:tw-no-underline tw-text-black hover:tw-text-black tw-hidden group-hover:tw-block tw-left-9"
              >+91 84691-49288</a
            >
          </div>
          } @else {
          <div
            class="tw-w-10 tw-h-[2.6rem] tw-py-2 tw-px-3 hover:tw-w-[9.76rem] tw-transition-all tw-duration-300 tw-bg-white tw-border tw-border-BluePrimary tw-cursor-pointer tw-rounded-full tw-absolute tw-group -tw-left-10 -tw-top-[1.3rem] tw-overflow-hidden"
          >
            <svg-icon
              src="assets/images/callicon.svg"
              class="tw-max-w-full tw-max-h-full group-hover:tw-fill-white tw-fill-black tw-transition-all tw-duration-300 group-hover:tw-opacity-0"
              [applyClass]="true"
            />
            <a
              href="tel:+918469149288"
              class="tw-font-semibold tw-opacity-0 group-hover:tw-opacity-100 tw-absolute tw-w-[9.5rem] tw-transition-all tw-duration-300 tw-top-2 hover:tw-no-underline tw-text-black hover:tw-text-black tw-hidden group-hover:tw-block"
              >+91 84691-49288</a
            >
          </div>
          }
        </div>

        <div class="tw-px-6">
          <a
            rel="nofollow"
            [routerLink]="isLoggedIn ? '/dashboard' : '/login'"
            class="tw-px-6 tw-py-2 tw-border tw-rounded-full tw-border-BluePrimary tw-cursor-pointer hover:tw-border-white hover:tw-scale-[1.01] hover:tw-shadow-md tw-transition-all tw-duration-300 hover:tw-no-underline tw-text-black hover:tw-text-black"
          >
            {{ isLoggedIn ? "Dashboard" : "Login" }}
          </a>
        </div>
        @if(layoutService.getStartedAsShown){
        <div
          class="tw-font-semibold tw-px-5 tw-py-2.5 gradient-button tw-rounded-full before:tw-rounded-full"
          (click)="layoutService.hideGetStartedAs()"
        >
          Go Back
        </div>
        } @else {
        <div
          class="tw-font-semibold tw-px-5 tw-py-2.5 gradient-button tw-rounded-full before:tw-rounded-full"
          (click)="changeShowGetStartedAs()"
        >
          {{ isLoggedIn ? "Logout" : "Get Started As" }}
        </div>
        }
      </div>
    </div>
  </header>

  <!-- Mobile -->
  <header
    class="tw-fixed tw-top-0 tw-w-full tw-z-50 tw-bg-white xl:tw-hidden tw-flex tw-justify-between tw-items-center tw-gap-5 tw-px-6 tw-py-4"
  >
    <!-- logo -->
    <a routerLink="/" class="tw-cursor-pointer">
      <svg-icon
        alt="Careernaksha"
        src="assets/images/logoh.svg"
        width="100%"
        height="100%"
        class="tw-h-9"
      />
    </a>
    <div id="menuToggle">
      <input
        id="menuCheckbox"
        type="checkbox"
        [checked]="expandMobileMenu"
        aria-label="menuCheckbox"
      />
      <label class="menuToggle tw-px-3" (click)="toggleMenu()">
        <div class="menuBar bar--top"></div>
        <div class="menuBar bar--middle"></div>
        <div class="menuBar bar--bottom"></div>
      </label>
      <div
        class="mobileMenu tw-h-[0px] tw-w-full tw-fixed tw-top-[4rem] tw-right-0 tw-bg-white tw-z-50 tw-transition-all tw-duration-500 tw-flex tw-flex-col tw-overflow-auto no-scrollbar"
      >
        <!-- Navbar Menu -->
        <a
          aria-label="Call"
          href="tel:+918469149288"
          class="tw-w-full tw-px-8 tw-flex tw-items-center tw-gap-4 tw-mt-3"
        >
          <svg-icon
            src="/assets/images/callicon.svg"
            svgClass="tw-px-2 tw-w-8 tw-h-8 tw-rounded-full tw-border-BluePrimary tw-border"
          />
          <span class="tw-font-semibold tw-text-sm">+91 84691-49288</span>
        </a>
        <div
          class="tw-h-[63vh] tw-w-full tw-px-6 tw-overflow-auto no-scrollbar tw-mt-3"
        >
          <div class="tw-h-[55vh] tw-w-full">
            <!-- Menu1 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckbox"
                (change)="changeMobileMenu(1)"
                [checked]="curMobileMenuOpen === 1"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                Psychometric Test
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 1 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content temp"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      routerLink="/formdetails"
                    >
                      Career Test</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      href="https://www.onetonline.org/"
                      target="_blank"
                      >O*NET Framework</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      target="_blank"
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSepPLhHWgsRlQ7t5vEbijVfy2fuKS6p70AOPL33rzQ2w3oHPw/viewform"
                      >Case History</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      routerLink="/aicounsellor"
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200 tw-text-black"
                      >Career AI Assistant</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- Menu2 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckBox"
                (change)="changeMobileMenu(2)"
                [checked]="curMobileMenuOpen === 2"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                Students/Parents
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 2 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <a
                    routerLink="/psychometrics"
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >8th Class and Below</span
                    >
                  </a>
                  <a
                    routerLink="/psychometrics"
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >9th & 10th Class</span
                    >
                  </a>
                  <a
                    routerLink="/psychometrics"
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >11th & 12th Class</span
                    >
                  </a>
                  <a
                    routerLink="/psychometrics"
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >UG & PG Students</span
                    >
                  </a>
                </ul>
              </div>
            </div>
            <!-- Menu3 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckBox"
                (change)="changeMobileMenu(3)"
                [checked]="curMobileMenuOpen === 3"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                Admissions
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 3 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      routerLink="/globalvidhya"
                      >GlobalVidhya</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >University Connect</span
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >School Connect</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- Menu4 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckBox"
                (change)="changeMobileMenu(4)"
                [checked]="curMobileMenuOpen === 4"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                Professionals
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 4 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Freshers & Graduates</span
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Working in MSME</span
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >IT Industry</span
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Leadership</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- Menu5 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckBox"
                (change)="changeMobileMenu(5)"
                [checked]="curMobileMenuOpen === 5"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                Corporates
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 5 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Employee Engagement</span
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      href="https://careerskill.org/"
                      class="hover:tw-bg-white tw-text-black hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Social CSR</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- Menu6 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckBox"
                (change)="changeMobileMenu(6)"
                [checked]="curMobileMenuOpen === 6"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                Counsellors
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 6 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      href="https://community.careernaksha.com/"
                      >Community</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      routerLink="/career-counsellor-program"
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      >National Certification Program</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdHJE_5O9VBCHwSvmgNcLN0Zy_NJdjzbLlCrby7PtOoejQoPw/viewform"
                      target="_blank"
                      class="hover:tw-bg-white tw-text-black hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Freelancing Work opportunity</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdPFCuLESgWLIangIrmLODow8Q6WAVQDe9SEA-qQQQZgMxHWw/viewform"
                      target="_blank"
                      class="hover:tw-bg-white tw-text-black hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Retreat</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- Menu7 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckBox"
                (change)="changeMobileMenu(7)"
                [checked]="curMobileMenuOpen === 7"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                Blogs
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 7 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      routerLink="/blogs"
                      >Articles</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Stories</span
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Edufair</span
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <span
                      class="hover:tw-bg-white hover:tw-text-BluePrimary hover:tw-no-underline active:!tw-bg-gray-200"
                      >Nirdeshika</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- Menu8 -->
            <div class="tw-collapse tw-collapse-arrow">
              <input
                aria-label="checkbox"
                type="checkbox"
                class="tw-min-h-0 navbarMenuCheckBox"
                (change)="changeMobileMenu(8)"
                [checked]="curMobileMenuOpen === 8"
              />
              <div
                class="tw-collapse-title tw-py-3 tw-min-h-0 tw-font-semibold"
              >
                About us
              </div>
              <div
                [style.height]="
                  curMobileMenuOpen === 8 ? 'fit-content' : 'auto'
                "
                class="tw-collapse-content"
              >
                <ul
                  class="tw-dropdown-content tw-z-[1] tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-px-4 tw-bg-white tw-text-sm tw-rounded-lg tw-w-max"
                >
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      routerLink="/about-us"
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      >Vision, Mission & History</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      routerLink="/team"
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      >Core Team and Advisory</a
                    >
                  </li>
                  <li
                    class="!tw-text-black tw-border-2 tw-border-white hover:tw-border-l-BluePrimary tw-pl-[2px] hover:tw-pr-0 tw-transition-all"
                  >
                    <a
                      routerLink="/partners"
                      class="hover:tw-bg-white hover:tw-text-BluePrimary tw-text-black hover:tw-no-underline active:!tw-bg-gray-200"
                      >Partners</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-row tw-w-full tw-justify-center tw-gap-2.5">
          <a
            rel="nofollow"
            [routerLink]="isLoggedIn ? '/dashboard' : '/login'"
            class="tw-font-semibold tw-px-5 tw-py-2.5 tw-text-black"
          >
            {{ isLoggedIn ? "Dashboard" : "Login" }}
          </a>
          <div
            class="tw-font-semibold tw-px-6 tw-py-2.5 gradient-button tw-rounded-full before:tw-rounded-full"
            (click)="changeShowGetStartedAs()"
          >
            {{ isLoggedIn ? "Logout" : "Get Started As" }}
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
