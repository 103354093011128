<!--Get Stated as overlay Desktop-->

<div
  class="tw-fixed tw-top-0 tw-left-0 tw-w-screen tw-h-screen xl:tw-flex tw-justify-center tw-items-center tw-bg-white tw-hidden"
>
  <div
    @showModalBackground
    class="tw-w-full tw-h-full tw-rounded-3xl tw-flex tw-flex-col tw-gap-4 tw-items-center lg:tw-pt-40 tw-py-12 tw-px-12 tw-z-[52] tw-scale-[0.8]"
  >
    <div
      class="tw-text-3xl tw-bg-clip-text tw-bg-[length:200%_200%] tw-bg-GradientBlueLightBlue tw-animate-[getStartedAsBtnBg_15s_ease-in-out_infinite] tw-text-transparent tw-font-bold tw-text-center"
    >
      Get Started as
    </div>
    <div class="tw-flex tw-gap-4 lg:tw-flex-row tw-flex-col">
      <a
        [routerLink]="['/register']"
        [queryParams]="{ m: 'student' }"
        (click)="closeGetStartedAs()"
        class="tw-flex tw-flex-col tw-items-center tw-justify-between"
      >
        <img
          alt="Student"
          src="assets/images/student.svg"
          class="lg:tw-block tw-hidden"
        />
        <div
          class="tw-inline-block tw-relative tw-cursor-pointer tw-outline-none tw-border-0 tw-align-middle tw-p-0 tw-bg-transparent tw-text-inherit tw-w-[14rem] tw-h-auto tw-group tw-pointer-events-auto"
        >
          <span
            class="tw-transition-all tw-duration-300 tw-relative tw-block tw-w-12 tw-h-12 tw-m-0 tw-rounded-[3rem] tw-bg-BluePrimary group-hover:tw-w-full tw-shadow-lg"
            aria-hidden="true"
          >
            <span
              class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-m-auto tw-bg-white tw-left-2.5 tw-w-5 tw-h-0.5 [background:none] before:tw-absolute before:-tw-top-1 before:tw-right-0.5 before:tw-w-2.5 before:tw-h-2.5 before:tw-border-t-[0.125rem] before:tw-border-r-[0.125rem] before:tw-border-white before:tw-rotate-45 group-hover:[background:#fff] group-hover:tw-translate-x-4"
            ></span>
          </span>
          <span
            class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-py-3 tw-ml-7 tw-font-semibold tw-leading-[1.6] tw-text-center tw-text-black group-hover:tw-text-white"
            >Student</span
          >
        </div>
      </a>
      <a
        [routerLink]="['/register']"
        [queryParams]="{ m: 'professional' }"
        (click)="closeGetStartedAs()"
        class="tw-flex tw-flex-col tw-items-center tw-justify-between"
      >
        <img
          alt="Professional"
          src="assets/images/professional.svg"
          class="lg:tw-block tw-hidden"
        />
        <div
          class="tw-inline-block tw-relative tw-cursor-pointer tw-outline-none tw-border-0 tw-align-middle tw-p-0 tw-bg-transparent tw-text-inherit tw-w-[14rem] tw-h-auto tw-group tw-pointer-events-auto"
        >
          <span
            class="tw-transition-all tw-duration-300 tw-relative tw-block tw-w-12 tw-h-12 tw-m-0 tw-rounded-full tw-bg-BluePrimary group-hover:tw-w-full tw-shadow-lg"
            aria-hidden="true"
          >
            <span
              class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-m-auto tw-bg-white tw-left-2.5 tw-w-5 tw-h-0.5 [background:none] before:tw-absolute before:-tw-top-1 before:tw-right-0.5 before:tw-w-2.5 before:tw-h-2.5 before:tw-border-t-[0.125rem] before:tw-border-r-[0.125rem] before:tw-border-white before:tw-rotate-45 group-hover:[background:#fff] group-hover:tw-translate-x-4"
            ></span>
          </span>
          <span
            class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-py-3 tw-ml-7 tw-font-semibold tw-leading-[1.6] tw-text-center tw-text-black group-hover:tw-text-white"
            >Professional</span
          >
        </div>
      </a>
      <a
        [routerLink]="['/register']"
        [queryParams]="{ m: 'counsellor' }"
        (click)="closeGetStartedAs()"
        class="tw-flex tw-flex-col tw-items-center tw-justify-between"
      >
        <img
          alt="Counsellor"
          src="assets/images/counsellor.svg"
          class="lg:tw-block tw-hidden"
        />
        <div
          class="tw-inline-block tw-relative tw-cursor-pointer tw-outline-none tw-border-0 tw-align-middle tw-p-0 tw-bg-transparent tw-text-inherit tw-w-[14rem] tw-h-auto tw-group tw-pointer-events-auto"
        >
          <span
            class="tw-transition-all tw-duration-300 tw-relative tw-block tw-w-12 tw-h-12 tw-m-0 tw-bg-BluePrimary group-hover:tw-w-full tw-shadow-lg tw-rounded-full"
            aria-hidden="true"
          >
            <span
              class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-m-auto tw-bg-white tw-left-2.5 tw-w-5 tw-h-0.5 [background:none] before:tw-absolute before:-tw-top-1 before:tw-right-0.5 before:tw-w-2.5 before:tw-h-2.5 before:tw-border-t-[0.125rem] before:tw-border-r-[0.125rem] before:tw-border-white before:tw-rotate-45 group-hover:[background:#fff] group-hover:tw-translate-x-4"
            ></span>
          </span>
          <span
            class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-py-3 tw-ml-5 tw-font-semibold tw-leading-[1.6] tw-text-center tw-text-black group-hover:tw-text-white"
            >Counsellor</span
          >
        </div>
      </a>
      <a
        [routerLink]="['/register']"
        [queryParams]="{ m: 'institute' }"
        (click)="closeGetStartedAs()"
        class="tw-flex tw-flex-col tw-items-center tw-justify-between"
      >
        <img
          alt="Institute"
          src="assets/images/getstartedinstitute.svg"
          class="lg:tw-block tw-hidden"
        />
        <div
          class="tw-inline-block tw-relative tw-cursor-pointer tw-outline-none tw-border-0 tw-align-middle tw-p-0 tw-bg-transparent tw-text-inherit tw-w-[18rem] tw-h-auto tw-group tw-pointer-events-auto"
        >
          <span
            class="tw-transition-all tw-duration-300 tw-relative tw-block tw-w-12 tw-h-12 tw-m-0 tw-rounded-full tw-bg-BluePrimary group-hover:tw-w-full tw-shadow-lg"
            aria-hidden="true"
          >
            <span
              class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-m-auto tw-bg-white tw-left-2.5 tw-w-5 tw-h-0.5 [background:none] before:tw-absolute before:-tw-top-1 before:tw-right-0.5 before:tw-w-2.5 before:tw-h-2.5 before:tw-border-t-[0.125rem] before:tw-border-r-[0.125rem] before:tw-border-white before:tw-rotate-45 group-hover:[background:#fff] group-hover:tw-translate-x-4"
            ></span>
          </span>
          <span
            class="tw-transition-all tw-duration-300 tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-py-3 tw-ml-16 tw-font-semibold tw-leading-[1.6] tw-text-center tw-text-black group-hover:tw-text-white"
            >Institute/Organization</span
          >
        </div>
      </a>
    </div>
  </div>
</div>

<!--Get Stated as overlay mobile-->
<div
  class="tw-fixed tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center tw-bg-white xl:tw-hidden tw-overflow-y-auto"
>
  <div
    @showModalBackground
    class="tw-w-full tw-h-full tw-rounded-3xl tw-flex tw-flex-col tw-gap-4 tw-items-center lg:tw-pt-28 tw-py-12 tw-z-[52] tw-scale-[0.8] tw-pointer-events-none"
  >
    <div
      class="tw-text-3xl tw-bg-clip-text tw-bg-[length:200%_200%] tw-bg-GradientBlueLightBlue tw-animate-[getStartedAsBtnBg_15s_ease-in-out_infinite] tw-text-transparent tw-font-bold tw-text-center"
    >
      Get Started as
    </div>
    <div class="tw-flex xl:tw-flex-row tw-flex-col">
      <!-- login1 -->
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-12"
      >
        <img alt="Student" src="assets/images/student.svg" class="tw-w-28" />
        <a
          [routerLink]="['/register']"
          [queryParams]="{ m: 'student' }"
          (click)="closeGetStartedAs()"
          class="tw-cursor-pointer tw-w-full tw-h-auto tw-group tw-pointer-events-auto tw-bg-BGColors tw-text-center tw-px-5 tw-py-2.5 tw-rounded-full tw-border tw-border-BluePrimary tw-font-semibold"
        >
          Student
        </a>
      </div>

      <!-- login2 -->
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-12"
      >
        <img
          alt="Professional"
          src="assets/images/professional.svg"
          class="tw-w-28"
        />
        <a
          [routerLink]="['/register']"
          [queryParams]="{ m: 'professional' }"
          (click)="closeGetStartedAs()"
          class="tw-cursor-pointer tw-w-full tw-h-auto tw-group tw-pointer-events-auto tw-bg-BGColors tw-text-center tw-px-5 tw-py-2.5 tw-rounded-full tw-border tw-border-BluePrimary tw-font-semibold"
        >
          Professional
        </a>
      </div>

      <!-- login3 -->
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-12"
      >
        <img
          alt="Counsellor"
          src="assets/images/counsellor.svg"
          class="tw-w-28"
        />
        <a
          [routerLink]="['/register']"
          [queryParams]="{ m: 'counsellor' }"
          (click)="closeGetStartedAs()"
          class="tw-cursor-pointer tw-w-full tw-h-auto tw-group tw-pointer-events-auto tw-bg-BGColors tw-text-center tw-px-5 tw-py-2.5 tw-rounded-full tw-border tw-border-BluePrimary tw-font-semibold"
        >
          Counsellor
        </a>
      </div>

      <!-- login4 -->
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-12"
      >
        <img
          alt="Institute"
          src="assets/images/getstartedinstitute.svg"
          class="tw-w-28"
        />
        <a
          [routerLink]="['/register']"
          [queryParams]="{ m: 'institute' }"
          (click)="closeGetStartedAs()"
          class="tw-cursor-pointer tw-w-full tw-h-auto tw-group tw-pointer-events-auto tw-bg-BGColors tw-text-center tw-px-5 tw-py-2.5 tw-rounded-full tw-border tw-border-BluePrimary tw-font-semibold"
        >
          Institute/Organization
        </a>
      </div>

      <div
        class="tw-flex tw-flex-col tw-gap-2 tw-w-full tw-items-center tw-mb-12 tw-mt-6"
      >
        <div class="tw-relative tw-w-full tw-flex tw-justify-center">
          <span class="tw-z-20 tw-bg-white tw-px-2">or</span>
          <div
            class="tw-absolute tw-w-full tw-h-[1px] tw-bg-black tw-z-10 tw-top-1/2"
          ></div>
        </div>
        <a
          rel="nofollow"
          routerLink="/login"
          (click)="closeGetStartedAs()"
          class="tw-cursor-pointer tw-w-fit tw-h-auto tw-group tw-pointer-events-auto tw-bg-white tw-text-center tw-px-10 tw-py-2.5 tw-rounded-full tw-border tw-border-BluePrimary tw-font-semibold"
        >
          Login
        </a>
      </div>
    </div>
  </div>
</div>
