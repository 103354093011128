import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
  signal,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { AuthService } from '@/app/services/auth.service';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterLink,
} from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { LayoutService } from '@/app/services/state/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  imports: [AngularSvgIconModule, RouterLink],
})
export class HeaderComponent implements OnInit, OnDestroy {
  curMobileMenuOpen = 0;
  isLoggedIn = false;
  expandMobileMenu = false;
  routerSubcription: Subscription;

  @ViewChildren('navList') navLists: ElementRef[];

  navLinks: Array<{
    name: string;
    subNavLinks: Array<{ name: string; url: string; type?: 'EXTERNAL_LINK' }>;
  }> = [
    {
      name: 'Psychometric Test',
      subNavLinks: [
        { name: 'Career Test', url: '/formdetails' },
        {
          name: 'O*NET Framework',
          url: 'https://www.onetonline.org/',
          type: 'EXTERNAL_LINK',
        },
        {
          name: 'Case History',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSepPLhHWgsRlQ7t5vEbijVfy2fuKS6p70AOPL33rzQ2w3oHPw/viewform',
          type: 'EXTERNAL_LINK',
        },
        {
          name: 'Career AI Assistant',
          url: '/aicounsellor',
        },
      ],
    },
    {
      name: 'Students/Parents',
      subNavLinks: [
        { name: '8th Class and Below', url: '/psychometrics' },
        { name: '9th & 10th Class', url: '/psychometrics' },
        { name: '11th & 12th Class', url: '/psychometrics' },
        { name: 'UG & PG Students', url: '/psychometrics' },
      ],
    },
    {
      name: 'Admissions',
      subNavLinks: [
        { name: 'GlobalVidhya', url: '/globalvidhya' },
        { name: 'University Connect', url: '' },
        { name: 'School Connect', url: '' },
      ],
    },
    {
      name: 'Professionals',
      subNavLinks: [
        {
          name: 'Freshers & Graduates',
          url: '',
        },
        {
          name: 'Working in MSME',
          url: '',
        },
        {
          name: 'IT Industry',
          url: '',
        },
        {
          name: 'Leadership',
          url: '',
        },
      ],
    },
    {
      name: 'Corporates',
      subNavLinks: [
        {
          name: 'Employee Engagement',
          url: '',
        },
        {
          name: 'Social CSR',
          url: 'https://careerskill.org/',
          type: 'EXTERNAL_LINK',
        },
      ],
    },
    {
      name: 'Counsellors',
      subNavLinks: [
        {
          name: "Search Counsellor",
          url: '/counsellors/search',
        },
        {
          name: 'Community',
          url: 'https://community.careernaksha.com/',
          type: 'EXTERNAL_LINK',
        },
        {
          name: 'National Certification Program',
          url: '/career-counsellor-program',
        },
        {
          name: 'Freelancing Work opportunity',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSdHJE_5O9VBCHwSvmgNcLN0Zy_NJdjzbLlCrby7PtOoejQoPw/viewform',
          type: 'EXTERNAL_LINK',
        },
        {
          name: 'Retreat',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSdPFCuLESgWLIangIrmLODow8Q6WAVQDe9SEA-qQQQZgMxHWw/viewform',
          type: 'EXTERNAL_LINK',
        },
      ],
    },
    {
      name: 'Blogs',
      subNavLinks: [
        { name: 'Articles', url: '/blogs' },
        { name: 'Stories', url: '' },
        { name: 'Edufair', url: '' },
        { name: 'Nirdeshika', url: '' },
      ],
    },
    {
      name: 'About Us',
      subNavLinks: [
        { name: 'Vision, Mission & History', url: 'about-us' },
        { name: 'Core Team and Advisory', url: '/team' },
        { name: 'Partners', url: '/partners' },
      ],
    },
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    protected layoutService: LayoutService
  ) {}

  changeShowGetStartedAs() {
    if (this.isLoggedIn) {
      this.authService.logoutUser();
      this.isLoggedIn = false;
      return;
    }
    this.layoutService.showGetStartedAs();
    this.expandMobileMenu = false;
  }

  changeMobileMenu(n: number) {
    this.curMobileMenuOpen = this.curMobileMenuOpen === n ? 0 : n;
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.loggedIn();
    this.routerSubcription = this.router.events.subscribe((event) => {
      // Close mobile menu on route change
      if (event instanceof NavigationStart) {
        this.layoutService.hideGetStartedAs();
      } else if (event instanceof NavigationEnd) {
        this.expandMobileMenu = false;
        this.curMobileMenuOpen = 0;
      }
      // Update login status
      this.isLoggedIn = this.authService.loggedIn();
    });
  }

  ngOnDestroy(): void {
    this.routerSubcription.unsubscribe();
  }

  toggleMenu() {
    this.expandMobileMenu = !this.expandMobileMenu;
  }

  toggleNav() {
    this.navLists.forEach((x) => {
      x.nativeElement.style.display =
        x.nativeElement.style.display === 'none' ? 'flex' : 'none';
    });
  }
  showNav() {
    this.navLists.forEach((x) => {
      x.nativeElement.style.display = 'flex';
    });
  }

  hideNav() {
    this.navLists.forEach((x) => {
      x.nativeElement.style.display = 'none';
    });
  }

  handleNavTitleClick() {
    this.showNav();
  }
}
