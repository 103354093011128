<!-- Footer -->
<div
  id="mainfooter"
  class="tw-w-screen tw-flex tw-justify-center tw-items-center tw-bg-BGColors xl:tw-px-16 tw-px-12"
>
  <div
    class="tw-w-full tw-flex tw-flex-col tw-gap-8 tw-max-w-[1440px] tw-items-center tw-overflow-visible lg:tw-pt-14 tw-pt-10 tw-pb-6"
  >
    <!-- top section -->
    <div class="tw-flex tw-w-full tw-gap-7 tw-flex-wrap tw-justify-center">
      <!-- logo -->
      <div class="xl:tw-w-auto tw-w-full">
        <img
          appLazyLoad="assets/images/footerlogo.svg"
          alt="Footer logo"
          width="104px"
          height="104px"
          class="tw-min-w-[6.5rem]"
        />
      </div>
      <!-- about us -->
      <div
        class="tw-flex tw-flex-col tw-min-w-[14rem] xl:tw-max-w-[17rem] tw-max-w-full tw-gap-4"
      >
        <span class="tw-font-semibold">About us</span>
        <div class="tw-text-sm">
          CareerNaksha is the most trusted online-offline platform for career
          psychometric assessment, career counselling, guidance, admissions in
          India & around the world. We help school students, UG/PG students,
          graduates and working professionals achieve their career dreams.
        </div>
      </div>
      <!-- social section -->
      <div
        class="xl:tw-w-max tw-flex tw-flex-col xl:tw-items-center tw-gap-4 xl:tw-max-w-[16rem] tw-max-w-full tw-w-full"
      >
        <span class="tw-font-semibold tw-w-full">
          Social | Partners |
          <a
            href="https://careernaksha.notion.site/CareerNaksha-Job-Board-140f4751d2668038848ac4b83f09b700"
            class="tw-text-black"
            target="_blank"
            >Career</a
          >
        </span>
        <div
          class="tw-flex tw-gap-5 tw-w-full tw-items-center tw-justify-start"
        >
          <a
            target="_blank"
            aria-label="Instagram"
            class="tw-text-black"
            href="https://www.instagram.com/careernaksha"
          >
            <svg-icon
              src="assets/images/instagram.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
          <a
            aria-label="facebook"
            class="tw-text-black"
            target="_blank"
            href="https://www.facebook.com/careernaksha"
          >
            <svg-icon
              src="assets/images/facebook.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
          <a
            aria-label="youtube"
            class="tw-text-black"
            target="_blank"
            href="https://www.youtube.com/careernaksha"
          >
            <svg-icon
              src="assets/images/youtube.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
          <a
            aria-label="linkedin"
            class="tw-text-black"
            target="_blank"
            href="https://www.linkedin.com/company/careernaksha"
          >
            <svg-icon
              src="assets/images/linkedin.svg"
              class="tw-h-7 tw-w-7 tw-brightness-0 hover:tw-brightness-100 tw-transition-all"
              [applyClass]="true"
            />
          </a>
        </div>
        <a
          aria-label="call"
          class="tw-flex tw-gap-2 tw-items-center tw-w-full"
          href="tel:+918469149288"
        >
          <svg-icon src="assets/images/footer/call.svg" />
          <span class="tw-text-sm">+91-8469149288 </span>
        </a>
        <a
          class="tw-flex tw-gap-2 tw-items-center tw-w-full"
          href="mailto:support@careernaksha.com"
        >
          <svg-icon src="assets/images/footer/mail.svg" />
          <span class="tw-text-sm">support&#64;careernaksha.com </span>
        </a>
        <div class="tw-flex tw-gap-2 tw-items-center tw-w-full">
          <svg-icon src="assets/images/footer/loc.svg" />
          <span class="tw-text-sm"
            >302, Saffron Complex, Fatehgunj, Vadodara, Gujarat 390008</span
          >
        </div>
      </div>
      <!-- onet section -->
      <div
        class="tw-flex tw-flex-col tw-items-center tw-gap-4 xl:tw-max-w-[19rem]"
      >
        <span class="tw-font-semibold tw-w-full"> O*Net Framework </span>
        <div class="tw-w-full">
          <img
            width="auto"
            height="auto"
            alt="ostar"
            class="tw-w-32"
            appLazyLoad="assets/images/footer/ostar.png"
          />
        </div>
        <p class="tw-text-sm">
          This site incorporates information from O*NET Web Services by the U.S.
          Department of Labor, Employment and Training Administration
          (USDOL/ETA). <br />
          <span class="tw-font-bold">O*NET® is a trademark of USDOL/ETA.</span>
        </p>
      </div>
      <!-- contact section -->
      <div
        class="xl:tw-w-max tw-w-min tw-flex tw-flex-col tw-gap-2 tw-min-w-[15rem]"
      >
        <app-telephone-input
          (inputEvent)="contactFormPhone = $event"
          #RequestCallback
        />
        <input
          type="text"
          name=""
          id=""
          placeholder="Email"
          [(ngModel)]="contactFormEmail"
          class="tw-rounded-md tw-border tw-border-SmallHeading tw-px-3 tw-text-sm tw-py-2.5 tw-font-semibold"
        />
        <input
          type="text"
          name=""
          id=""
          placeholder="Your Query"
          [(ngModel)]="contactFormMessage"
          class="tw-rounded-md tw-border tw-border-SmallHeading tw-px-3 tw-text-sm tw-py-2.5 tw-font-semibold"
        />
        <div tabindex="0" class="tw-dropdown">
          <div
            tabindex="0"
            (click)="
              isContactFormInterestedInOpen = !isContactFormInterestedInOpen
            "
            class="tw-rounded-md tw-border tw-border-SmallHeading tw-px-3 tw-text-sm tw-py-2.5 tw-bg-white tw-cursor-pointer tw-font-semibold tw-text-SmallHeading"
          >
            {{
              contactFormInterestedIn
                ? contactFormInterestedIn
                : "Are you interested in"
            }}
          </div>
          <ul
            [style.display]="isContactFormInterestedInOpen ? 'block' : 'none'"
            class="tw-dropdown-content tw-text-xs tw-bg-white tw-mt-1 tw-w-full tw-rounded-md tw-border tw-border-SmallHeading tw-cursor-pointer"
          >
            <li
              (click)="changeInterestedIn('Career Counselling')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Career Counselling
            </li>
            <li
              (click)="changeInterestedIn('Career Planning')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Career Planning
            </li>
            <li
              (click)="changeInterestedIn('Psychometrics Testing')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Psychometrics Testing
            </li>
            <li
              (click)="changeInterestedIn('Studyabroad Program')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Studyabroad Program
            </li>
            <li
              (click)="changeInterestedIn('Profile Building')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Profile Building
            </li>
            <li
              (click)="changeInterestedIn('College and University')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              College and University
            </li>
            <li
              (click)="changeInterestedIn('Join Community')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Join Community
            </li>
            <li
              (click)="
                changeInterestedIn('Coupon Code for Career Analytics Report')
              "
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Coupon Code for Career Analytics Report
            </li>
            <li
              (click)="changeInterestedIn('Certification & Training')"
              class="tw-font-semibold tw-px-3 tw-py-2 hover:tw-bg-black hover:tw-bg-opacity-10"
            >
              Certification & Training
            </li>
          </ul>
        </div>
        <div class="tw-w-full tw-flex tw-justify-end">
          <button
            (click)="onContactFormSubmit()"
            [disabled]="isSendButtonDisabled()"
            class="tw-px-3 tw-py-1.5 tw-rounded-md disabled:tw-bg-[#9F9F9F] disabled:tw-text-black tw-font-bold disabled:tw-text-opacity-20 disabled:tw-bg-opacity-50 tw-text-white tw-bg-BluePrimary"
          >
            Submit
          </button>
        </div>
      </div>
    </div>

    <!-- cities -->
    <div
      class="tw-flex tw-flex-col tw-gap-6 tw-w-full lg:tw-items-center tw-items-start lg:tw-pl-[11rem] lg:tw-pr-[2.5rem] lg:tw-mt-8 tw-mt-4"
    >
      <div class="tw-w-full tw-font-semibold tw-text-center lg:tw-text-left">
        Available Offline & Online in English | Hindi | Gujarati
      </div>

      <!-- Desktop Cities -->
      <div
        class="lg:tw-flex tw-text-sm tw-gap-16 lg:tw-flex-row tw-flex-col tw-w-full tw-hidden"
      >
        <!-- in gujarat -->
        <div class="tw-flex tw-flex-col tw-gap-8">
          <span class="tw-font-bold tw-text-base">In Gujarat</span>
          <div class="tw-flex tw-gap-x-8 tw-gap-y-2 tw-flex-wrap tw-max-w-2xl">
            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/ahmedabad">
                Ahmedabad
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vadodara">
                Vadodara
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/anand">Anand</a>
              <a class="tw-text-black" routerLink="/career-counselling/bhavnagar">
                Bhavnagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/gandhinagar">
                Gandhinagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/rajkot"> Rajkot </a>
              <a class="tw-text-black" routerLink="/career-counselling/surat"> Surat</a>
              <a class="tw-text-black" routerLink="/career-counselling/bharuch">Bharuch</a>
              <a class="tw-text-black" routerLink="/career-counselling/ankleshwar"
                >Ankleshwar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/nadiad"> Nadiad </a>
              <a class="tw-text-black" routerLink="/career-counselling/surendranagar">
                Surendranagar
              </a>
            </div>

            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/navsari">Navsari</a>
              <a class="tw-text-black" routerLink="/career-counselling/valsad">Valsad</a>
              <a class="tw-text-black" routerLink="/career-counselling/vapi">Vapi </a>
              <a class="tw-text-black" routerLink="/career-counselling/jamnagar"
                >Jamnagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/junagadh">
                Junagadh</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/porbandar"
                >Porbandar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/gandhidham">
                Gandhidham</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/godhra"> Godhra</a>
              <a class="tw-text-black" routerLink="/career-counselling/dahod"> Dahod </a>
              <a class="tw-text-black" routerLink="/career-counselling/palanpur"
                >Palanpur</a
              >
            </div>

            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/patan"> Patan </a>
              <a class="tw-text-black" routerLink="/career-counselling/mehsana"> Mehsana</a>
              <a class="tw-text-black" routerLink="/career-counselling/morbi"> Morbi</a>
              <a class="tw-text-black" routerLink="/career-counselling/veraval "
                >Veraval
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/botad">Botad </a>
              <a class="tw-text-black" routerLink="/career-counselling/jetpur">Jetpur </a>
              <a class="tw-text-black" routerLink="/career-counselling/kalol"> Kalol </a>
              <a class="tw-text-black" routerLink="/career-counselling/halol"> Halol </a>
              <a class="tw-text-black" routerLink="/career-counselling/dabhoi">Dabhoi</a>
              <a class="tw-text-black" routerLink="/career-counselling/dwarka"> Dwarka </a>
            </div>

            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/mundra"> Mundra </a>
              <a class="tw-text-black" routerLink="/career-counselling/amreli"> Amreli</a>
              <a class="tw-text-black" routerLink="/career-counselling/gondal">Gondal</a>
              <a class="tw-text-black" routerLink="/career-counselling/sanand"> Sanand</a>
              <a class="tw-text-black" routerLink="/career-counselling/dholka"> Dholka </a>
              <a class="tw-text-black" routerLink="/career-counselling/siddhpur">
                Siddhpur</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/bardoli "
                >Bardoli
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/palitana">
                Palitana</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/deesa"> Deesa </a>
              <a class="tw-text-black" routerLink="/career-counselling/bhuj"> Bhuj</a>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-2 tw-ml-6 lg:tw-ml-0">
              <a class="tw-text-black" routerLink="/career-counselling/modasa">Modasa</a>
              <a class="tw-text-black" routerLink="/career-counselling/wankaner"
                >Wankaner</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/himmatnagar">
                Himmatnagar
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vadnagar"
                >Vadnagar
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vasad"> Vasad</a>
              <a class="tw-text-black" routerLink="/career-counselling/khambhat">
                Khambhat
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/kheda"> Kheda</a>
              <a class="tw-text-black" routerLink="/career-counselling/visnagar">
                Visnagar
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vansda">Vansda </a>
              <a class="tw-text-black" routerLink="/career-counselling/vallabhvidyanagar">
                Vallabhvidyanagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/chandkheda"
                >Chandkheda</a
              >
            </div>
          </div>
        </div>

        <!-- in india -->
        <div class="tw-flex tw-flex-col tw-gap-8">
          <span class="tw-font-bold tw-text-base">In India</span>
          <div class="tw-flex tw-gap-x-8 tw-gap-y-2 tw-flex-wrap">
            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/gurgaon">Gurgaon</a>
              <a class="tw-text-black" routerLink="/career-counselling/noida"> Noida </a>
              <a class="tw-text-black" routerLink="/career-counselling/jaipur"> Jaipur</a>
              <a class="tw-text-black" routerLink="/career-counselling/hyderabad">
                Hyderabad
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/faridabad">
                Faridabad</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/delhi"> Delhi</a>
              <a class="tw-text-black" routerLink="/career-counselling/chandigarh">
                Chandigarh</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/bangalore"
                >Bangalore</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/lucknow">
                Lucknow
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/kerala">Kerala</a>
              <a class="tw-text-black" routerLink="/career-counselling/durg">Durg</a>
              <a class="tw-text-black" routerLink="/career-counselling/rohini">Rohini</a>
              <a class="tw-text-black" routerLink="/career-counselling/rajasthan"
                >Rajasthan</a
              >
            </div>

            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/dwarka"> Dwarka </a>
              <a class="tw-text-black" routerLink="/career-counselling/pune"> Pune </a>
              <a class="tw-text-black" routerLink="/career-counselling/mumbai"> Mumbai </a>
              <a class="tw-text-black" routerLink="/career-counselling/indore">Indore</a>
              <a class="tw-text-black" routerLink="/career-counselling/bhopal"> Bhopal</a>
              <a class="tw-text-black" routerLink="/career-counselling/panchkula"
                >Panchkula</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/aurangabad">
                Aurangabad
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/latur">Latur</a>
              <a class="tw-text-black" routerLink="/career-counselling/wardha">Wardha</a>
              <a class="tw-text-black" routerLink="/career-counselling/meerut">Meerut</a>
              <a class="tw-text-black" routerLink="/career-counselling/mysore">Mysore</a>
              <a class="tw-text-black" routerLink="/career-counselling/rewa">Rewa</a>
            </div>
          </div>
        </div>

        <!-- in aborad -->
        <div class="tw-flex tw-flex-col tw-gap-8">
          <span class="tw-font-bold tw-text-base">Outside India</span>
          <div class="tw-flex tw-gap-x-8 tw-gap-y-2 tw-flex-wrap">
            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/canada">Canada</a>
              <a class="tw-text-black" routerLink="/career-counselling/perth">Perth</a
              ><a class="tw-text-black" routerLink="/career-counselling/united kingdom"
                >United Kingdom</a
              >
              <span class="tw-text-black">Dubai</span>
              <span class="tw-text-black">Abu Dhabi</span>
              <span class="tw-text-black">Qatar</span>
              <span class="tw-text-black">Riyadh</span>
              <span class="tw-text-black">Jeddah</span>
              <span class="tw-text-black">Dammam</span>
              <span class="tw-text-black">Oman</span>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-2">
              <a class="tw-text-black" routerLink="/career-counselling/sydney">Sydney</a>
              <a class="tw-text-black" routerLink="/career-counselling/melbourne"
                >Melbourne</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/toronto">Toronto</a>
              <a class="tw-text-black" routerLink="/career-counselling/europe">Europe</a>
              <a class="tw-text-black" routerLink="/career-counselling/calgary">Calgary</a>
              <a class="tw-text-black" routerLink="/career-counselling/singapore"
                >Singapore</a
              >

              <span class="tw-text-black">Muscat</span>
              <span class="tw-text-black">Bahrain</span>
              <span class="tw-text-black">Kuwait</span>
              <span class="tw-text-black">Uganda</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:tw-hidden tw-flex tw-flex-col tw-gap-7 tw-w-full">
        <!-- Gujarat -->
        <div class="tw-flex tw-justify-center tw-w-full tw-flex-col tw-gap-8">
          <span class="tw-font-bold tw-text-xl tw-w-full tw-text-center"
            >In Gujarat</span
          >
          <div class="tw-w-full tw-flex tw-justify-between">
            <div class="tw-w-1/2 tw-flex tw-flex-col">
              <a class="tw-text-black" routerLink="/career-counselling/ahmedabad">
                Ahmedabad
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vadodara">
                Vadodara
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/anand">Anand</a>
              <a class="tw-text-black" routerLink="/career-counselling/bhavnagar">
                Bhavnagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/gandhinagar">
                Gandhinagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/rajkot"> Rajkot </a>
              <a class="tw-text-black" routerLink="/career-counselling/surat"> Surat</a>
              <a class="tw-text-black" routerLink="/career-counselling/bharuch">Bharuch</a>
              <a class="tw-text-black" routerLink="/career-counselling/ankleshwar"
                >Ankleshwar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/nadiad"> Nadiad </a
              ><a class="tw-text-black" routerLink="/career-counselling/navsari">Navsari</a>
              <a class="tw-text-black" routerLink="/career-counselling/valsad">Valsad</a>
              <a class="tw-text-black" routerLink="/career-counselling/vapi">Vapi </a>
              <a class="tw-text-black" routerLink="/career-counselling/jamnagar"
                >Jamnagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/junagadh">
                Junagadh</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/porbandar"
                >Porbandar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/gandhidham">
                Gandhidham</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/godhra"> Godhra</a>
              <a class="tw-text-black" routerLink="/career-counselling/dahod"> Dahod </a>
              <a class="tw-text-black" routerLink="/career-counselling/palanpur">Palanpur</a
              ><a class="tw-text-black" routerLink="/career-counselling/patan"> Patan </a>
              <a class="tw-text-black" routerLink="/career-counselling/mehsana"> Mehsana</a>
              <a class="tw-text-black" routerLink="/career-counselling/morbi"> Morbi</a>
              <a class="tw-text-black" routerLink="/career-counselling/veraval "
                >Veraval
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/botad">Botad </a>
            </div>
            <div class="tw-w-1/2 tw-flex tw-flex-col">
              <a class="tw-text-black" routerLink="/career-counselling/halol"> Halol </a>
              <a class="tw-text-black" routerLink="/career-counselling/dabhoi">Dabhoi</a>
              <a class="tw-text-black" routerLink="/career-counselling/dwarka"> Dwarka </a
              ><a class="tw-text-black" routerLink="/career-counselling/mundra"> Mundra </a>
              <a class="tw-text-black" routerLink="/career-counselling/amreli"> Amreli</a>
              <a class="tw-text-black" routerLink="/career-counselling/gondal">Gondal</a>
              <a class="tw-text-black" routerLink="/career-counselling/sanand"> Sanand</a>
              <a class="tw-text-black" routerLink="/career-counselling/dholka"> Dholka </a>
              <a class="tw-text-black" routerLink="/career-counselling/siddhpur">
                Siddhpur</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/bardoli "
                >Bardoli
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/palitana">
                Palitana</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/deesa"> Deesa </a>
              <a class="tw-text-black" routerLink="/career-counselling/bhuj"> Bhuj</a
              ><a class="tw-text-black" routerLink="/career-counselling/modasa">Modasa</a>
              <a class="tw-text-black" routerLink="/career-counselling/wankaner"
                >Wankaner</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/himmatnagar">
                Himmatnagar
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vadnagar "
                >Vadnagar
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vasad"> Vasad</a>
              <a class="tw-text-black" routerLink="/career-counselling/khambhat">
                Khambhat
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/kheda"> Kheda</a>
              <a class="tw-text-black" routerLink="/career-counselling/visnagar">
                Visnagar
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/vansda">Vansda </a>
              <a class="tw-text-black" routerLink="/career-counselling/vallabhvidyanagar">
                Vallabhvidyanagar</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/jetpur">Jetpur </a>
              <a class="tw-text-black" routerLink="/career-counselling/kalol"> Kalol </a>
            </div>
          </div>
        </div>
        <!-- india -->
        <div class="tw-flex tw-justify-center tw-w-full tw-flex-col tw-gap-8">
          <span class="tw-font-bold tw-text-xl tw-w-full tw-text-center"
            >In India</span
          >
          <div class="tw-w-full tw-flex tw-justify-between">
            <div class="tw-w-1/2 tw-flex tw-flex-col">
              <a class="tw-text-black" routerLink="/career-counselling/gurgaon">Gurgaon</a>
              <a class="tw-text-black" routerLink="/career-counselling/noida"> Noida </a>
              <a class="tw-text-black" routerLink="/career-counselling/jaipur"> Jaipur</a>
              <a class="tw-text-black" routerLink="/career-counselling/hyderabad">
                Hyderabad
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/faridabad">
                Faridabad</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/delhi"> Delhi</a>
              <a class="tw-text-black" routerLink="/career-counselling/chandigarh">
                Chandigarh</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/bangalore"
                >Bangalore</a
              >
            </div>
            <div class="tw-w-1/2 tw-flex tw-flex-col">
              <a class="tw-text-black" routerLink="/career-counselling/dwarka"> Dwarka </a>
              <a class="tw-text-black" routerLink="/career-counselling/pune"> Pune </a>
              <a class="tw-text-black" routerLink="/career-counselling/mumbai"> Mumbai </a>
              <a class="tw-text-black" routerLink="/career-counselling/indore">Indore</a>
              <a class="tw-text-black" routerLink="/career-counselling/bhopal"> Bhopal</a>
              <a class="tw-text-black" routerLink="/career-counselling/panchkula"
                >Panchkula</a
              >
              <a class="tw-text-black" routerLink="/career-counselling/aurangabad">
                Aurangabad
              </a>
              <a class="tw-text-black" routerLink="/career-counselling/latur"> Latur</a>
            </div>
          </div>
        </div>
        <!-- aborad -->
        <div class="tw-flex tw-justify-center tw-w-full tw-flex-col tw-gap-8">
          <span class="tw-font-bold tw-text-xl tw-w-full tw-text-center"
            >Outside India</span
          >
          <div class="tw-w-full tw-flex tw-justify-between">
            <div class="tw-w-1/2 tw-flex tw-flex-col">
              <span class="tw-text-black">Dubai</span>
              <span class="tw-text-black">Abu Dhabi</span>
              <span class="tw-text-black">Qatar</span>
              <span class="tw-text-black">Riyadh</span>
              <span class="tw-text-black">Jeddah</span>
              <span class="tw-text-black">Dammam</span>
              <span class="tw-text-black">Oman</span>
            </div>
            <div class="tw-w-1/2 tw-flex tw-flex-col">
              <span class="tw-text-black">Muscat</span>
              <span class="tw-text-black">Bahrain</span>
              <span class="tw-text-black">Kuwait</span>
              <span class="tw-text-black">Uganda</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="tw-flex tw-flex-col tw-gap-4 lg:tw-pl-[10.5rem] lg:tw-pr-[2.5rem] lg:tw-mt-8 tw-mt-4 tw-w-full"
    >
      <div class="tw-font-semibold tw-py-2 tw-flex tw-flex-col">
        Career Counselling & Guidance Solutions :
        <div class="tw-flex tw-gap-x-10 tw-flex-wrap tw-mt-4">
          <div class="tw-font-semibold tw-text-base tw-w-28">
            <div>Students</div>
            <div>Graduates</div>
          </div>
          <div class="tw-font-semibold tw-text-base tw-w-28">
            <div>Freshers</div>
            <div>Professionals</div>
          </div>
          <div class="tw-font-semibold tw-text-base tw-w-28">
            <div>Schools</div>
            <div>Psychologists</div>
          </div>
          <div class="tw-font-semibold tw-text-base tw-w-28">
            <div>Counsellors</div>
            <div>Coaches</div>
          </div>
          <div class="tw-font-semibold tw-text-base tw-w-28">
            <div>Colleges</div>
            <div>Universities</div>
          </div>
          <div class="tw-font-semibold tw-text-base tw-w-28">
            <div>Corporates</div>
            <div>Industries</div>
          </div>
          <div
            class="tw-font-semibold tw-text-base tw-w-28 tw-flex lg:tw-flex-col"
          >
            <div>Companies</div>
            <div class="tw-ml-[4.125rem] lg:tw-ml-0">NGOs</div>
          </div>
        </div>
      </div>
      <div
        class="tw-w-full tw-flex tw-justify-between tw-flex-wrap lg:tw-pr-28"
      >
        <div class="tw-text-sm lg:tw-w-1/2 tw-w-full">
          <span class="tw-font-semibold tw-text-base">Schools :</span>
          <div
            class="tw-flex tw-gap-7 tw-mt-4 lg:tw-justify-start tw-justify-between tw-w-full"
          >
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>Career Test</span>
              <span>Assessments</span>
              <span>Psychometrics</span>
              <span>Grade 5th to 8th</span>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>Grade 9th & 10th</span>
              <span>Grade 11th & 12th</span>
              <span>Aptitude Test</span>
            </div>
          </div>
        </div>
        <div class="tw-text-sm lg:tw-w-1/2 tw-w-full tw-mt-4 lg:tw-mt-0">
          <span class="tw-font-semibold tw-text-base"
            >Services & Products :</span
          >
          <div
            class="tw-flex tw-gap-7 tw-mt-4 lg:tw-justify-start tw-justify-between tw-w-full"
          >
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>Career Test</span>
              <span>Psychometrics</span>
              <span>Counselling</span>
              <span>Mentorship</span>
              <span>Profile Building</span>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>College or University Admissions</span>
              <span>Stream Selection</span>
              <span>Career Confusion or Clarity</span>
              <span>Write Article on Career</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tw-w-full tw-flex tw-justify-between tw-flex-wrap tw-mt-4 lg:tw-pr-28"
      >
        <div class="tw-text-sm lg:tw-w-1/2 tw-w-full">
          <span class="tw-font-semibold tw-text-base">Colleges :</span>
          <div
            class="tw-flex lg:tw-gap-7 tw-gap-2 tw-mt-4 lg:tw-justify-start tw-justify-between tw-flex-col lg:tw-flex-row tw-w-full"
          >
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>Career Test for Engineering Students</span>
              <span>Management Students</span>
              <span>Science Students</span>
              <span>Medical Health Students</span>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>Post Graduates</span>
              <span>UG or PG Students</span>
              <span>Job Placement Assistance</span>
            </div>
          </div>
        </div>
        <div class="tw-text-sm lg:tw-w-1/2 tw-w-full tw-mt-4 lg:tw-mt-0">
          <span class="tw-font-semibold tw-text-base">Other Assessments :</span>
          <div
            class="tw-flex lg:tw-gap-7 tw-gap-2 tw-mt-4 lg:tw-justify-start tw-justify-between tw-flex-col lg:tw-flex-row tw-w-full"
          >
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>MBTI Personality Test</span>
              <span>DISC Personality Test</span>
              <span>Learning Style Assessment</span>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
              <span>DMIT Assessment</span>
              <span>Maladjustment Assessment</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <swiper-container
      swiperElement
      [config]="blogSwiperConfig"
      init="false"
      class="tw-w-full tw-max-w-5xl tw-mt-4"
    >
      <swiper-slide class="tw-flex tw-justify-center"
        ><a routerLink="/payments"
          ><img
            alt="paytm"
            class="tw-h-10 tw-w-auto"
            height="auto"
            width="auto"
            appLazyLoad="assets/images/footer/payment/paytm.png" /></a
      ></swiper-slide>
      <swiper-slide class="tw-flex tw-justify-center"
        ><a routerLink="/payments"
          ><img
            appLazyLoad="assets/images/footer/payment/visa.png"
            alt="visa"
            class="tw-h-10 tw-w-auto"
            height="auto"
            width="auto" /></a
      ></swiper-slide>
      <swiper-slide class="tw-flex tw-justify-center"
        ><a routerLink="/payments"
          ><img
            appLazyLoad="assets/images/footer/payment/gpay.png"
            alt="gpay"
            class="tw-h-10 tw-w-auto"
            height="auto"
            width="auto" /></a></swiper-slide
      ><swiper-slide class="tw-flex tw-justify-center">
        <a routerLink="/payments"
          ><img
            appLazyLoad="assets/images/footer/payment/razorpay.png"
            alt="razorpay"
            class="tw-h-10 tw-w-auto"
            height="auto"
            width="auto" /></a></swiper-slide
      ><swiper-slide class="tw-flex tw-justify-center">
        <a routerLink="/payments"
          ><img
            appLazyLoad="assets/images/footer/payment/mc.png"
            alt="mc"
            class="tw-h-10 tw-w-auto"
            height="auto"
            width="auto" /></a></swiper-slide
      ><swiper-slide class="tw-flex tw-justify-center">
        <a routerLink="/payments">
          <img
            appLazyLoad="assets/images/footer/payment/neft.png"
            alt="neft"
            class="tw-h-10 tw-w-auto"
            height="auto"
            width="auto" /></a></swiper-slide
      ><swiper-slide class="tw-flex tw-justify-center">
        <a routerLink="/payments"
          ><img
            appLazyLoad="assets/images/footer/payment/imps.png"
            alt="imps"
            class="tw-h-10 tw-w-auto tw-ml-2"
            height="auto"
            width="auto" /></a
      ></swiper-slide>
    </swiper-container>
    <div class="tw-w-full tw-text-center lg:tw-text-base tw-text-sm">
      Built with ❤️ in India | CareerNaksha Copyrights
      {{ currentYear }} &#64;Realtime Data Analysis Pvt. Ltd.
      <div class="tw-w-full tw-text-center">
        <a class="tw-text-black" routerLink="/privacy-policy" rel="nofollow"
          >Privacy Policy</a
        >
        |
        <a
          class="tw-text-black"
          routerLink="/data-privacy-policy"
          rel="nofollow"
          >Data Privacy Policy</a
        >|
        <a class="tw-text-black" routerLink="/terms-conditions" rel="nofollow"
          >Terms & Conditions</a
        >
      </div>
    </div>
  </div>
</div>
