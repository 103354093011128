import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { isAdmin } from './guards/isAdmin.guard';

export const routes: Routes = [
  {
    path: '',
    title: 'Home | CareerNaksha',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'homepage',
    title: 'Home | CareerNaksha',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'admin',
    canActivate: [isAdmin],
    loadChildren: () =>
      import('./pages/admin/admin.routes').then((m) => m.routes),
  },
  {
    path: 'team',
    title:
      'Core Team | Team MNC Corporate Startups | Experienced Counselors | CareerNaksha',
    loadComponent: () =>
      import('./pages/about/about.component').then((m) => m.AboutComponent),
  },
  {
    path: 'gallery',
    title:
      'Gallery | School College Events | Career Youth Seminars | GlobalVidhya | Media',
    loadChildren: () =>
      import('./pages/gallery/gallery.routes').then((m) => m.routes),
  },
  {
    path: 'blogs',
    title: 'CareerNaksha - Blogs',
    loadComponent: () =>
      import('@/app/pages/blogs/blogs.component').then(
        (m) => m.AllBlogsComponent
      ),
  },
  {
    path: 'blogs/:id',
    loadComponent: () =>
      import('@/app/pages/blog/blog.component').then((m) => m.BlogComponent),
  },
  // {
  //   path: 'cities',
  //   redirectTo: 'career-counselling',
  // },
  {
    path: 'career-counselling',
    title: 'CareerNaksha - cities',
    loadChildren: () =>
      import('./pages/city/city.routes').then((m) => m.routes),
  },
  {
    path: 'aicounsellor',
    loadComponent: () =>
      import('@/app/pages/aicounsellor/aicounsellor.component').then(
        (m) => m.LandingPageComponent
      ),
  },
  {
    path: 'aicounsellor/dash',
    loadComponent: () =>
      import('@/app/pages/aicounsellor/dash/dash.component').then(
        (m) => m.CareerAssistanceAiComponent
      ),
  },
  {
    path: 'aicounsellor/chat',
    loadComponent: () =>
      import('@/app/pages/aicounsellor/chat/chat.component').then(
        (m) => m.AiChatIframeComponent
      ),
  },
  {
    path: 'partners',
    loadComponent: () =>
      import('@/app/pages/partners/partners.component').then(
        (m) => m.PartnersComponent
      ),
  },
  {
    path: 'thankyou',
    title: 'Career counselling & career guidance | Thank you page',
    loadComponent: () =>
      import('@/app/pages/thankyou/thankyou.component').then(
        (m) => m.ThankyouComponent
      ),
  },
  {
    path: 'globalvidhya',
    title: 'Study Abroad | Overseas Education | Study in India',
    loadComponent: () =>
      import('@/app/pages/globalvidhya/globalvidhya.component').then(
        (m) => m.GlobalvidhyaComponent
      ),
  },
  {
    path: 'quiz',
    title: 'Career Quiz | Career Assessment Test | CareerNaksha',
    canActivate: [authGuard],
    loadComponent: () =>
      import('@/app/pages/quiz/quiz.component').then((m) => m.QuizComponent),
  },
  {
    path: 'data-privacy-policy',
    title: 'Data Privacy Policy | CareerNaksha',
    loadComponent: () =>
      import(
        '@/app/pages/data-privacy-policy/data-privacy-policy.component'
      ).then((m) => m.DataPrivacyPolicyComponent),
  },
  {
    path: 'psychometrics',
    loadChildren: () =>
      import('./pages/psychometrics/psychometrics.routes').then(
        (m) => m.routes
      ),
  },
  {
    path: 'study-abroad-inquiry',
    title: 'Study Abroad Inquiry | CareerNaksha',
    loadComponent: () =>
      import(
        './pages/study-abroad-inquiry/study-abroad-inquiry.component'
      ).then((m) => m.StudyAbroadInquiryComponent),
  },
  {
    path: 'logout',
    title: 'Logout | CareerNaksha',
    loadComponent: () =>
      import('./pages/logout/logout.component').then((m) => m.LogoutComponent),
  },
  {
    path: 'dashboard',
    title: 'Dashboard Redirect | CareerNaksha',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        (m) => m.DashboardRedirectComponent
      ),
  },
  {
    path: 'about-us',
    title: 'About Us | CareerNaksha',
    loadComponent: () =>
      import('./pages/about-us/about-us.component').then(
        (m) => m.AboutUsComponent
      ),
  },
  {
    path: 'fb-data-deletion-instructions',
    title: 'Facebook Data Deletion Instructions | CareerNaksha',
    loadComponent: () =>
      import(
        './pages/fb-data-deletion-instructions/fb-data-deletion-instructions.component'
      ).then((m) => m.FbDataDeletionInstructionsComponent),
  },
  {
    path: 'career-counsellor-program',
    title: 'Career Counsellor Program | CareerNaksha',
    loadComponent: () =>
      import(
        './pages/career-counsellor-program/career-counsellor-program.component'
      ).then((m) => m.CareerCounsellorProgramComponent),
  },
  {
    path: 'terms-conditions',
    title: 'Terms and Conditions | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/terms-conditions/terms-conditions.component').then(
        (m) => m.TermsConditionsComponent
      ),
  },
  {
    path: 'career-library',
    title: 'Career Library | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/career-library/career-library.component').then(
        (m) => m.CareerLibraryComponent
      ),
  },
  {
    path: 'formdetails',
    title: 'CareerNaksha | Psycometric Test',
    loadComponent: () =>
      import('@/app/pages/formdetails/formdetails.component').then(
        (m) => m.FormdetailsComponent
      ),
  },
  {
    path: 'resetpwd/:token',
    title: 'CareerNaksha | Recover Password',
    loadComponent: () =>
      import('@/app/pages/resetpwd/resetpwd.component').then(
        (m) => m.ResetpwdComponent
      ),
  },
  {
    path: 'privacy-policy',
    title:
      'Privacy Policy | Compliance | Global Users Data & Protection | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/privacy-policy/privacy-policy.component').then(
        (m) => m.PrivacyPolicyComponent
      ),
  },
  {
    path: 'roi',
    title: 'Find return on your educational investment | ROI calculator',
    loadComponent: () =>
      import('@/app/pages/roi/roi.component').then((m) => m.RoiComponent),
  },
  {
    path: 'neet-predictor',
    title: 'NEET College Predictor | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/neet-predictor/neet-predictor.component').then(
        (m) => m.NeetPredictorComponent
      ),
  },
  {
    path: 'college-predictor',
    title: 'College Predictor | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/college-predictor/college-predictor.component').then(
        (m) => m.CollegePredictorComponent
      ),
  },
  {
    path: 'career-counsellor/:slug',
    title: 'Career Counsellor | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/career-counsellor/screen/screen.component').then(
        (m) => m.CounsellorscreenComponent
      ),
  },
  {
    path: 'test-report',
    title: 'Career Counsellor | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/test-report/test-report.component').then(
        (m) => m.TestReportComponent
      ),
  },
  {
    path: 'career-counsellor/state/:state',
    title: 'Career Counsellor | CareerNaksha',
    loadComponent: () =>
      import('@/app/pages/career-counsellor/state/state.component').then(
        (m) => m.StateCounsellorComponent
      ),
  },
  {
    path: 'certificate/verify/:id',
    title: 'Career Counsellor | CareerNaksha',
    loadComponent: () =>
      import(
        '@/app/pages/certificate/verify/verify-certificate.component'
      ).then((m) => m.VerifyCertificateComponent),
  },
  {
    path: 'recoverpwd',
    title: 'CareerNaksha | Recover Password',
    loadComponent: () =>
      import('@/app/pages/recoverpwd/recoverpwd.component').then(
        (m) => m.RecoverpwdComponent
      ),
  },
  {
    path: 'assessment/dope-bird-personality',
    title: 'Dope Bird Personality Test | CareerNaksha',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        '@/app/pages/assessment/dope-bird-personality/dope-bird-personality.component'
      ).then((m) => m.DopePersonalityTestComponent),
  },
  {
    path: 'career-analytics',
    title: 'CareerNaksha - Career Analytics',
    loadComponent: () =>
      import('@/app/pages/career-analytics/career-analytics.component').then(
        (m) => m.CareerAnalyticsComponent
      ),
  },
  {
    path: 'interest-test',
    title: 'Interest Test | CareerNaksha',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./pages/interest-test/interest-test.routes').then(
        (m) => m.routes
      ),
  },
  {
    path: 'assessment/aiquiz',
    title: 'AI Quiz | CareerNaksha',
    canActivate: [authGuard],
    loadComponent: () =>
      import('@/app/pages/assessment/aiquiz/aiquiz.component').then(
        (m) => m.QuizComponent
      ),
  },
  {
    path: 'payments',
    title: 'CareerNaksha | Payment',
    loadComponent: () =>
      import('./pages/payments/payments.component').then(
        (m) => m.PaymentsComponent
      ),
  },
  {
    path: 'registration',
    title:
      'Create | Build | Transform | Enhance | Profile | Resume | CareerNaksha',
    loadComponent: () =>
      import('./pages/registration/registration.component').then(
        (m) => m.RegistrationComponent
      ),
  },
  {
    path: 'registration/:id',
    title:
      'Create | Build | Transform | Enhance | Profile | Resume | CareerNaksha',
    loadComponent: () =>
      import('./pages/registration/refer-id/refer-id.component').then(
        (m) => m.ReferIdComponent
      ),
  },
  {
    path: 'login',
    title: 'CareerNaksha Login - Career Counselling & Guidance',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'register',
    title: 'CareerNaksha Login - Career Counselling & Guidance',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'test',
    loadComponent: () =>
      import('./pages/test/test.component').then((m) => m.TestComponent),
  },
  {
    path: 'counsellors',
    loadChildren: () =>
      import('./pages/counsellors/counsellors.routes').then((m) => m.routes),
  },
  {
    path: 'not-found',
    title: 'Page not Found',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: '**',
    title: 'Page not Found',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
];
