<div
  class="tw-w-10 tw-h-10 lg:tw-w-14 lg:tw-h-14 tw-fixed tw-right-9 tw-bottom-20 lg:tw-right-28 lg:tw-bottom-28 tw-z-10"
>
  <ul
    [className]="
      'tw-absolute tw-inset-[-20px] tw-list-none tw-m-0 tw-p-0' +
      { open: isOpen }
    "
  >
    @for (link of navLinks; track $index){
    <li
      class="tw-absolute tw-w-0 tw-h-full tw-m-0 tw-left-1/2 tw-transition-transform tw-duration-700"
      [style.transform]="'rotate(' + angles[$index] + 'deg)'"
    >
      <input type="checkbox" [id]="'c' + ($index + 1)" hidden />
      <label
        [for]="'c' + ($index + 1)"
        [className]="
          'tw-absolute tw-left-1/2 tw-bottom-full tw-w-0 tw-h-0 tw-text-xs tw-text-center tw-bg-blue-100 tw-rounded-full tw-shadow-md tw-cursor-pointer tw-transition-all tw-duration-700 hover:tw-bg-blue-300' +
          { 'tw-bg-blue-500 tw-text-white': indexOfActiveLink === $index }
        "
        [style.width]="isOpen ? '45px' : '0'"
        [style.height]="isOpen ? '45px' : '0'"
        [style.lineHeight]="isOpen ? '40px' : '0'"
        [style.marginLeft]="isOpen ? '-40px' : '0'"
        [style.fontSize]="isOpen ? '14px' : '0'"
        [style.transform]="isOpen ? 'rotate(' + -angles[$index] + 'deg)' : ''"
        (click)="handleLabelClick($index)"
      >
        @if(link.href){
        <a [href]="link.href">
          @if(link['img']){
          <img [src]="link['img']" />
          } @else {
          {{ link.label }}
          }
        </a>
        } @else if(link.routerLink){
        <a [routerLink]="link.routerLink">
          @if(link['img']){
          <img [src]="link['img']" />
          } @else {
          {{ link.label }}
          }
        </a>
        } @else { @if(link['img']){
        <img [src]="link['img']" />
        } @else {
        {{ link.label }}
        } }
      </label>
    </li>
    }
  </ul>
  <button
    class="tw-relative tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-blue-500 tw-rounded-full tw-text-white tw-text-lg tw-shadow-md tw-transition hover:tw-bg-blue-700"
    (click)="toggleMenu()"
  >
    <img
      class="tw-w-5 tw-h-5 lg:tw-w-7 lg:tw-h-7"
      [src]="
        isOpen
          ? '/assets/images/icons/collapse.svg'
          : '/assets/images/icons/expand.svg'
      "
    />
  </button>
</div>
