// core angular imports
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Subscription } from 'rxjs';

// custom components
import { FooterComponent } from './components/footer/footer.component';
import { GetStartedAsComponent } from './components/get-started-as/get-started-as.component';
import { CallbackAlertComponent } from './components/callback-alert/callback-alert.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

// custom services
import { LayoutService } from './services/state/layout.service';
import { UtilsService } from './services/utils.service';
import { AuthService } from './services/auth.service';
import { AlertService } from './services/ui/alert.service';
import { NavComponent } from './components/circular-nav/circular-nav.component';

@Component({
  selector: 'app-root',
  imports: [
    HeaderComponent,
    RouterOutlet,
    LoadingSpinnerComponent,
    FooterComponent,
    CallbackAlertComponent,
    GetStartedAsComponent,
    NavComponent,
  ],
  providers: [LayoutService, UtilsService, AuthService, AlertService],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  routerSubscription: Subscription;

  constructor(
    private router: Router,
    protected layoutService: LayoutService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      } else if (event instanceof NavigationEnd) {
        this.isLoading = false;
        if (this.utilsService.isThisBrowser()) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
