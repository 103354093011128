import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { AuthService } from '@/app/services/auth.service';
import { RouterLink } from '@angular/router';
import { SwiperDirective } from '@/app/directives/swiper.directive';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TelephoneInputComponent } from '../telephone-input/telephone-input.component';
import { LazyLoadDirective } from '@/app/directives/lazy-load.directive';

@Component({
  selector: 'app-footer',
  imports: [
    RouterLink,
    SwiperDirective,
    FormsModule,
    AngularSvgIconModule,
    TelephoneInputComponent,
    LazyLoadDirective,
  ],
  providers: [AuthService],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FooterComponent {
  contactFormPhone = '';
  contactFormEmail = '';
  contactFormMessage = '';
  contactFormInterestedIn = '';
  isContactFormInterestedInOpen = false;
  currentYear = new Date().getFullYear();

  constructor(private authService: AuthService) {}

  public blogSwiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.rightBlogNav',
      prevEl: '.leftBlogNav',
      disabledClass: 'tw-opacity-30 tw-cursor-default',
    },
    autoplay: {
      delay: 6900,
    },
    breakpoints: {
      0: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      },
      1280: {
        slidesPerView: 7,
      },
    },
  };

  changeInterestedIn = (intIn: string) => {
    this.contactFormInterestedIn = intIn;
    this.isContactFormInterestedInOpen = false;
  };

  onContactFormSubmit = () => {
    if (
      this.contactFormEmail === '' ||
      this.contactFormInterestedIn === '' ||
      this.contactFormPhone === ''
    ) {
      alert('Please Enter Details');
    } else {
      const sendContact = {
        email: this.contactFormEmail,
        phone: this.contactFormPhone,
        interestedIn: this.contactFormInterestedIn,
        message: this.contactFormMessage,
      };

      this.authService.sendContactToDashboard(sendContact);
      this.clearForm();
    }
  };

  clearForm = () => {
    this.contactFormEmail = '';
    this.contactFormInterestedIn = '';
    this.contactFormPhone = '';
    this.contactFormMessage = '';
  };

  isSendButtonDisabled = () => {
    return (
      this.contactFormEmail === '' ||
      this.contactFormInterestedIn === '' ||
      this.contactFormPhone === ''
    );
  };
}
