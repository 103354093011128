<div
  class="tw-fixed lg:tw-top-[5.5rem] tw-pointer-events-none tw-top-[3.5rem] tw-font-semibold tw-w-full tw-flex tw-justify-center tw-z-[105]"
  [@callbackAlert]="alertService.alert().type"
>
  @if(alertService.alert().bgColor === 'green'){
  <span
    class="tw-bg-green-100 tw-text-GreenTertiary tw-border-GreenTertiary tw-px-5 tw-py-1.5 tw-rounded-lg tw-border tw-text-xs lg:tw-text-base"
  >
    {{ alertService.alert().message }}
  </span>
  } @if(alertService.alert().bgColor === 'red'){
  <span
    class="tw-bg-red-100 tw-text-red-500 tw-border-red-500 tw-px-5 tw-py-1.5 tw-rounded-lg tw-border tw-text-xs lg:tw-text-base"
  >
    {{ alertService.alert().message }}
  </span>
  }
</div>
