import { LayoutService } from '@/app/services/state/layout.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-get-started-as',
  templateUrl: './get-started-as.component.html',
  styleUrls: ['./get-started-as.component.css'],
  imports: [RouterLink, AngularSvgIconModule],
  animations: [
    trigger('showModalBackground', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms 100ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('showGetStartedAs', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms 150ms ease-out', style({ transform: 'none' })),
      ]),
      transition(':leave', [
        style({ transform: 'none' }),
        animate('200ms ease-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class GetStartedAsComponent {
  constructor(private layoutService: LayoutService) {}

  closeGetStartedAs() {
    this.layoutService.hideGetStartedAs();
  }
}
