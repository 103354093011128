import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component } from '@angular/core';
import { AlertService } from '@/app/services/ui/alert.service';

@Component({
  selector: 'app-callback-alert',
  imports: [],
  templateUrl: './callback-alert.component.html',
  styleUrl: './callback-alert.component.css',
  animations: [
    trigger('callbackAlert', [
      state('en', style({ opacity: '100%' })), // Define your desired scale values here
      state('ex', style({ opacity: '0%' })),
      transition(
        'ex => en',
        animate('0.5s 0s cubic-bezier(0.7, -0.4, 0.4, 1.4)')
      ),
      transition(
        'en => ex',
        animate('0.5s 0s cubic-bezier(0.7, -0.4, 0.4, 1.4)')
      ),
    ]),
  ],
})
export class CallbackAlertComponent {
  constructor(protected alertService: AlertService) {}
}
